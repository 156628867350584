import React, { useRef, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { useTranslation } from 'lib/i18n'

import Wrapper from 'components/Wrapper/Wrapper'
import useResponsive from 'hooks/useResponsive'

import TrustBannerItem from './TrustBannerItem'
import TrustBannerModal from './TrustBannerModal'
import { Item } from './types'

import styles from './TrustBanner.module.css'

const TrustBanner: React.FC = () => {
  const { isDesktop } = useResponsive()
  const { t } = useTranslation()
  const autoplay = useRef(
    Autoplay({ delay: 3000 }, (emblaRoot) => emblaRoot.parentElement),
  )
  const [emblaRef] = useEmblaCarousel({ loop: true, skipSnaps: true }, [
    autoplay.current,
  ])

  const [modalState, setModalState] = useState<{
    isOpen: boolean
    item: Item | null
  }>({ isOpen: false, item: null })

  const trustItems: Item[] = [
    {
      id: 'moneyback',
      title: t('TrustBanner.moneyBack.title', 'Money back guarantee'),
      modalText: t(
        'TrustBanner.moneyBack.modalText',
        "We want you to love your memmo and want to make sure it gives you and the recipient an amazing experience. Therefore if you're not happy with what you received we are happy to redo it or give you a full refund.",
      ),
      disclaimer: t(
        'TrustBanner.moneyBack.disclaimer',
        'This offer is not available for business orders but we are sure we can work out something with the talent that you are happy with.',
      ),
    },
    {
      id: 'securepayments',
      title: t('TrustBanner.securePayments.title', 'Secure payments'),
      modalText: t(
        'TrustBanner.securePayments.modalText',
        'If you paid by card we reserve the amount when you place your order but only charge you once you have received the video. If you paid by another method we will always do a full refund if the celebrity doesn’t respond.',
      ),
    },
    {
      id: '24h',
      title: t(
        'TrustBanner.twentyFourHours.title',
        '24 hour delivery available',
      ),
      modalText: t(
        'TrustBanner.twentyFourHours.modalText',
        "Sometimes you just need that last minute gift and that's why many celebrities on memmo are available for recording a memmo within 24 hours. When you checkout you simple select that you need it within 24 hours and the celebrity will go out of their way to make it for you as quickly as possible.*",
      ),
    },
  ]

  return (
    <>
      <div
        ref={isDesktop ? undefined : emblaRef}
        className={styles.trustBanner}
        onMouseLeave={isDesktop ? undefined : () => autoplay.current.play()}
        onMouseEnter={isDesktop ? undefined : () => autoplay.current.stop()}
      >
        <Wrapper className={styles.wrapper}>
          {trustItems.map((item) => (
            <TrustBannerItem
              key={item.title}
              item={item}
              onClick={() => setModalState({ isOpen: true, item })}
            />
          ))}
        </Wrapper>
      </div>
      {modalState.isOpen && (
        <TrustBannerModal
          onClose={() => setModalState({ isOpen: false, item: null })}
          item={modalState.item}
        />
      )}
    </>
  )
}

export default TrustBanner

import Typography from 'components/UI/Typography/Typography'

import TrustBannerIcon from './TrustBannerIcon'
import { Item } from './types'

import styles from './TrustBannerItem.module.css'

type TrustBannerItemProps = {
  item: Item
  onClick: () => void
}

const TrustBannerItem: React.FC<TrustBannerItemProps> = ({ item, onClick }) => {
  return (
    <div key={item.title} className={styles.item}>
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        className={styles.button}
      >
        <TrustBannerIcon id={item.id} />
        <Typography.MetaBold className={styles.title}>
          {item.title}
        </Typography.MetaBold>
      </div>
    </div>
  )
}

export default TrustBannerItem

import { ApolloError, gql, useQuery } from '@apollo/client'
import { currentMarketIdVar } from 'graphql/reactive'

import { LogoTypeProps } from 'components/ProfilePage/type'

const GET_PAYMENT_METHOD_LOGOS = gql`
  query getAvailablePaymentMethodLogos($marketId: String!) {
    paymentMethodLogos(marketId: $marketId)
  }
`
export type UsePaymentMethodLogosReturn = {
  loading: boolean
  error: ApolloError
  paymentMethodLogos: LogoTypeProps[]
}

export default function usePaymentMethodLogos(
  options = {},
): UsePaymentMethodLogosReturn {
  const marketId = currentMarketIdVar()

  const { loading, error, data } = useQuery(GET_PAYMENT_METHOD_LOGOS, {
    variables: {
      marketId,
    },
    ...options,
  })

  return {
    loading,
    error,
    paymentMethodLogos: data?.paymentMethodLogos,
  }
}

import React from 'react'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import Image from 'next/image'

import { GetImageProps, PaymentLogoProps } from '../type'
import twoco from './Logos/2co.png'
import alipay from './Logos/alipay.png'
import logoAmericanExpress from './Logos/american_express_blue.svg'
import amexSquare from './Logos/amex.svg'
import carteBancaire from './Logos/carte_bancaire.svg'
import dinersSquare from './Logos/diners.svg'
import discoverSquare from './Logos/discover.svg'
import klarna from './Logos/klarna.svg'
import maestroSquare from './Logos/maestro.svg'
import logoMastercard from './Logos/maestro_white_bg.svg'
import mastercardSquare from './Logos/mc.svg'
import logoPayPal from './Logos/paypal_white_bg.svg'
import logoSwish from './Logos/swish_no_text_logo.svg'
import visaSquare from './Logos/visa.svg'
import logoVisa from './Logos/visa_white_bg.svg'

import styles from './PaymentLogo.module.css'

const getImage: React.FC<GetImageProps> = ({ src, type, className }) => (
  <div className={styles.defaultStyle}>
    <Image
      src={src}
      width={45}
      height={31}
      alt={`${type}-logo`}
      className={className}
    />
  </div>
)

const PaymentLogo: React.FC<PaymentLogoProps> = ({
  type,
  className = styles.defaultStyle,
}) => {
  let logo

  switch (type) {
    case '2co':
      logo = getImage({ src: twoco, type, className })
      break
    case 'alipay':
      logo = getImage({ src: alipay, type, className })
      break
    case 'AmericanExpress':
      logo = getImage({ src: logoAmericanExpress, type, className })
      break
    case 'CarteBancaire':
      logo = getImage({ src: carteBancaire, type, className: styles.rounded })
      break
    case 'MasterCard':
      logo = getImage({ src: logoMastercard, type, className })
      break
    case 'PayPal':
      logo = getImage({ src: logoPayPal, type, className })
      break
    case 'Swish':
      logo = getImage({ src: logoSwish, type, className })
      break
    case 'Visa':
      logo = getImage({ src: logoVisa, type, className })
      break
    case 'visaSq':
      logo = getImage({ src: visaSquare, type, className })
      break
    case 'amexSq':
      logo = getImage({ src: amexSquare, type, className })
      break
    case 'dinersSq':
      logo = getImage({ src: dinersSquare, type, className })
      break
    case 'discoverSq':
      logo = getImage({ src: discoverSquare, type, className })
      break
    case 'maestroSq':
      logo = getImage({ src: maestroSquare, type, className })
      break
    case 'bcmcSq':
    case 'mcSq':
      logo = getImage({ src: mastercardSquare, type, className })
      break
    case 'klarna':
      logo = getImage({ src: klarna, type, className })
      break
    default:
      logo = (
        <div className={cn(styles.defaultLogo, className)}>
          <FontAwesomeIcon icon={faCreditCard} color="#000" />
        </div>
      )
      break
  }

  return logo
}

export default PaymentLogo

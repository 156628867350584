import React from 'react'

import {
  IconCreditCard,
  IconMoneyBack,
  IconTwentyFourHours,
} from 'components/UI/Icons'

import styles from './TrustBannerIcon.module.css'

type TrustBannerIconProps = {
  id: string
  height?: number
}

const TrustBannerIcon: React.FC<TrustBannerIconProps> = ({
  id,
  height = 24,
}) => {
  return (
    <>
      {id === '24h' && (
        <IconTwentyFourHours className={styles.icon} height={height} />
      )}
      {id === 'securepayments' && (
        <IconCreditCard className={styles.icon} height={height} />
      )}
      {id === 'moneyback' && (
        <IconMoneyBack className={styles.icon} height={height} />
      )}
    </>
  )
}

export default TrustBannerIcon

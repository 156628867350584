import React from 'react'
import cn from 'classnames'

import usePaymentMethodLogos from 'hooks/usePaymentMethodLogos'

import PaymentLogo from '../PaymentLogo/PaymentLogo'
import { ClassNameProps } from '../type'

import styles from './PaymentLogos.module.css'

type PaymentLogosProps = ClassNameProps & {
  left?: boolean
  right?: boolean
}

const PaymentLogos: React.FC<PaymentLogosProps> = ({
  className,
  left,
  right,
}) => {
  const { paymentMethodLogos } = usePaymentMethodLogos()

  return (
    <div
      className={cn(className, styles.logosWrapper, {
        [styles.left]: left,
        [styles.right]: right,
      })}
      data-cy="profilePageTrustLogos"
    >
      {paymentMethodLogos?.map((paymentMethodLogo) => (
        <PaymentLogo type={paymentMethodLogo} key={paymentMethodLogo} />
      ))}
    </div>
  )
}

export default PaymentLogos

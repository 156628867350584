import React from 'react'
import { useTranslation } from 'lib/i18n'

import PaymentLogos from 'components/ProfilePage/PaymentLogos/PaymentLogos'
import { Button } from 'components/UI/Button/Button'
import { ModalCard } from 'components/UI/ModalCard/ModalCard'
import Typography from 'components/UI/Typography/Typography'

import TrustBannerIcon from './TrustBannerIcon'
import { Item } from './types'

import styles from './TrustBannerModal.module.css'

type TrustModalBannerProps = {
  item: Item
  onClose: () => void
}

const TrustBannerModal: React.FC<TrustModalBannerProps> = ({
  item,
  onClose,
}) => {
  const { t } = useTranslation()
  const twentyFourExtraText = t(
    'TrustBannerModal.twentyFourHours',
    '* The talent may charge an extra cost for quicker delivery, you can see the price when completing your order.',
  )

  return (
    <ModalCard removeCloseButton onClose={onClose}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <TrustBannerIcon id={item.id} height={30} />
          <Typography.H5>{item.title}</Typography.H5>
        </div>
        <div className={styles.modalTextContainer}>
          <Typography.Body>
            {item.modalText}
            {item.disclaimer && (
              <>
                <br />
                <br />
                {item.disclaimer}
              </>
            )}
          </Typography.Body>

          {item.id === '24h' && (
            <Typography.Body className={styles.twentyFourExtraText}>
              {'\n\n'}
              {twentyFourExtraText}
            </Typography.Body>
          )}
          {item.id === 'securepayments' && (
            <PaymentLogos className={styles.payementLogos} />
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button size="small" onClick={() => onClose()}>
            {t('TrustBannerModal.confirmButton', 'Got it')}
          </Button>
        </div>
      </div>
    </ModalCard>
  )
}

export default TrustBannerModal
